@import "src/styles/scss/_variables.scss";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./calendar.css";
@import "./webview.scss";
@import "./popup.scss";
@import "./channelTalk.scss";
@import "./bottomsheet.scss";
@import "./map.scss";
@import "./banner.scss";
@import "./tippy.scss";

:root {
	--rsbs-w: 100%;
	--rsbs-max-w: 500px;
	--rsbs-ml: auto;
	--rsbs-mr: auto;
	--app-height: 100dvh;
	--app-content-height: calc(100dvh - 52px);
}

* {
	box-sizing: border-box !important;
	font-family: "Pretendard", Pretendard, -apple-system, BlinkMacSystemFont,
		system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
		"Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol", sans-serif;
	-webkit-overflow-scrolling: "touch";
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: transparent;
}

input,
textarea {
	-moz-user-select: auto;
	-webkit-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
}

.flex-import {
	display: flex !important;
}

.custom-scroll-bar::-webkit-scrollbar {
	width: 10px;
	height: 10px;

	@media screen and (max-width: 500px) {
		display: none;
	}
}

.custom-scroll-bar::-webkit-scrollbar-track {
	background: #f1f1f1;

	@media screen and (max-width: 500px) {
		display: none;
	}
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
	background-color: #cbcbd7;
	background-clip: padding-box;
	border-radius: 20px;
	border: 3px solid transparent;

	@media screen and (max-width: 500px) {
		display: none;
	}
}

.custom-scroll-bar-ad::-webkit-scrollbar {
	display: none;
	height: 8px;

	@media screen and (max-width: 500px) {
		display: none;
	}
}

.custom-scroll-bar-ad::-webkit-scrollbar-track {
	background: transparent;

	@media screen and (max-width: 500px) {
		display: none;
	}
}

.custom-scroll-bar-ad::-webkit-scrollbar-thumb {
	background-color: #c1c2bf;
	background-clip: padding-box;
	border-radius: 8px;

	@media screen and (max-width: 500px) {
		display: none;
	}
}

html,
body,
#app {
	@media screen and (max-width: 500px) {
		background-color: white;
	}
	@media screen and (min-width: 500px) {
		background-color: white;
	}
	// vaul 관련 버그. BottomSheet 내에서 다른 모달 (ex : 아임포트) 오픈시 터치 불가 버그 발생
	// https://github.com/emilkowalski/vaul/issues/492
	pointer-events: all !important;

	margin: 0;
	padding: 0;
	width: 100%;
	font-family: "Pretendard", Pretendard, -apple-system, BlinkMacSystemFont,
		system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
		"Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol", sans-serif;
}

body {
	background-color: transparent;
	color: $TEXT_NEW_GRAY_900;
}

body::-webkit-scrollbar {
	display: none;
}

#__next {
	max-width: 500px;
	margin: auto;
}

#app {
	display: flex;
}

input {
	font-size: 14px;
	margin: 0;
	padding: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

button {
	margin: 0;
	text-decoration: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}
button:focus {
	outline: none;
}

table {
	border-spacing: 0;
}

tr,
td,
th {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

li {
	padding: 0;
}

@font-face {
	font-family: "GmarketSansBold";
	src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff")
		format("woff");
	font-display: swap;
}

@font-face {
	font-family: "GmarketSansLight";
	src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff")
		format("woff");
	font-display: swap;
}
@font-face {
	font-family: "GongGothicMedium";
	src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/GongGothicMedium.woff")
		format("woff");
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

/*
- 레거시 라디오 스타일링
- checked 상태의 스타일링의 경우에는 tailwind로 대체했으나 
  나머지 스타일의 경우에는 사이드 이펙트가 우려돼 일단 두게 됨.
  24.04.26 김형겸
*/
[type="radio"] {
	vertical-align: middle;
	appearance: none;
	border: max(2px, 0.1em) solid #e7e7e7;
	border-radius: 50%;
	width: 1.25em;
	height: 1.25em;
}

[type="radio"]:focus-visible {
	outline-offset: max(2px, 0.1em);
	outline: max(2px, 0.1em) dotted tomato;
}

// for lightbox
.react-transform-component {
	display: block !important;
	width: auto !important;
	height: auto !important;
}

// datePicker 날짜 가리기
.react-datepicker__aria-live {
	display: none;
}

@layer components {
	.small-bullet-list li::marker {
		content: " ･  ";
	}
	.list-disc li {
		list-style-type: " ・ ";
	}
}

.yarl__hide-controls .yarl__navigation_prev,
.yarl__hide-controls .yarl__navigation_next,
.yarl__hide-controls .yarl__slide_button,
.yarl__hide-controls .yarl__slide_footer,
.yarl__hide-controls .yarl__toolbar {
	visibility: hidden;
}

.yarl__slide_image {
	@media screen and (min-width: 500px) {
		max-width: 500px !important;
	}
}
.yarl__video_container {
	@media screen and (min-width: 500px) {
		max-width: 500px !important;
	}
}
