.show-on-webview,
.show-on-webview-flex {
	display: none;
}

.hide-on-webview {
	display: inline;
}

.hide-on-webview-flex {
	display: flex;
}
