.bottom-sheet div::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

[data-rsbs-overlay] {
	z-index: 100;
	bottom: $BOTTOM_NAV_BAR_HEIGHT;

	@media screen and (min-width: 500px) {
		border-left: #e0e0d1 1px solid;
		border-right: #e0e0d1 1px solid;
	}
}

[data-rsbs-backdrop] {
	z-index: 100;
}

[data-rsbs-header] {
	height: 0px;
	padding: 0px;
	margin-bottom: 37px;
}

[data-rsbs-header]:before {
	display: none;
}
