.react-datepicker {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.react-datepicker__month-container {
		display: flex;
		flex-direction: column;

		gap: 10px;

		.react-datepicker__day-names {
			display: flex;
			justify-content: space-between;
			margin-left: -3.86%;
			margin-right: -3.86%;

			& > .react-datepicker__day-name {
				flex: 1;

				color: #a3a3a0;

				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				letter-spacing: -0.42px;

				text-align: center;

				&:first-of-type {
					color: #ff781f;
				}
			}
		}
		.react-datepicker__month {
			display: flex;
			flex-direction: column;

			gap: 10px;

			& > .react-datepicker__week {
				display: flex;
				justify-content: space-between;
				margin-left: -3.86%;
				margin-right: -3.86%;

				& > .react-datepicker__day {
					flex: 1;

					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					letter-spacing: -0.42px;
					text-align: center;

					padding: 8px 0;

					cursor: pointer;
				}

				.react-datepicker__day--selected,
				.react-datepicker__day--highlighted {
					background-image: url("/booking/calendar-circle.png");
					background-size: 37px;
					background-position: center;
					background-repeat: no-repeat;
				}

				.react-datepicker__day--in-range {
					background-image: linear-gradient(
						to bottom,
						rgba(255, 247, 19, 0.2),
						rgba(255, 247, 19, 0.2)
					);
				}

				.react-datepicker__day--range-start {
					background-image: url("/booking/calendar-circle.png"),
						linear-gradient(
							to left,
							rgba(255, 247, 19, 0.2) 50%,
							transparent 50%
						);
					background-size: 37px, auto;
					background-position: center;
					background-repeat: no-repeat;
				}

				.react-datepicker__day--range-end {
					background-image: url("/booking/calendar-circle.png"),
						linear-gradient(
							to right,
							rgba(255, 247, 19, 0.2) 50%,
							transparent 50%
						);
					background-size: 37px, auto;
					background-position: center;
					background-repeat: no-repeat;
				}

				.react-datepicker__day--today {
					position: relative;

					background-size: 37px;
					background-position: center;
					background-repeat: no-repeat;

					&:before {
						content: "";
						position: absolute;
						display: inline-block;
						height: 8px;
						width: 8px;
						background-color: #fff613;
						border-radius: 50%;
						bottom: 30px;
						left: calc(50% - 4px);
					}

					&:after {
						content: "오늘";
						color: #a3a3a0;
						position: absolute;
						top: 30px;
						left: calc(50% - 9px);
						font-size: 11px;
					}
				}

				.react-datepicker__day--disabled {
					color: #e4e4e1;

					cursor: not-allowed;
				}

				.react-datepicker__day--outside-month {
					visibility: hidden;
				}
			}
		}
	}
}
